// Catch-all route
// Avoids polluting the logs with 404 errors

import { Button } from "antd";
import type { ActionFunction, LoaderFunction } from "react-router";
import logo from "~/assets/compact-logo.png";
import logger from "~/modules/logging";

export const action: ActionFunction = async ({ request }) => {
  logger.warn(`404 ${request.method} Not Found: ${request.url}`);
  return Response.json({ message: "Unhandled POST request" }, { status: 404 });
};

export const loader: LoaderFunction = ({ request }) => {
  logger.warn(`404 ${request.method} Not Found: ${request.url}`);
  return { status: 404, message: "Not Found" };
};

export default function CatchAll() {
  return (
    <main className="flex flex-col min-h-screen justify-center items-center space-y-2">
      <img src={logo} alt="logo" className="h-10" />
      <h1 className="font-semibold text-3xl">Page Not Found</h1>
      <p>The page you're looking for doesn't exist.</p>
      <div>
        <Button type="primary" href="/">
          Go Home
        </Button>
      </div>
    </main>
  );
}
